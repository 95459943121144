import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import './Login.css';
import Spinner from '../Loader/Spinner';
import Popup from '../Popup';

const Login = ({ isProfileCompleted, setIsVerified, setIsProfileCompleted, API_URL, token }) => {
    const [mobile, setMobile] = useState('');
    const [mpin, setMpin] = useState(['', '', '', '']);
    const [userMpin, setUserMpin] = useState('');
    const [showMpin, setShowMpin] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [openRefInput, setOpenRefInput] = useState(false);
    const [error, setError] = useState('');
    const [refCode, setRefCode] = useState(''); // State to store referral code
    const [loading, setLoading] = useState(false);
    const inputRefs = useRef([]);
    const navigate = useNavigate();

    // Fetch referral code from URL query parameters
    useEffect(() => {
        const params = new URLSearchParams(window.location.search);
        const referralCode = params.get('refCode');
        if (referralCode) {
            setRefCode(referralCode);
        }
    }, []);

    // Handle MPIN input changes
    const handleMpinChange = (e, index) => {
        const { value } = e.target;
        if (/^\d$/.test(value)) {
            const newMpin = [...mpin];
            newMpin[index] = value;
            setMpin(newMpin);
            if (index < 3) inputRefs.current[index + 1].focus();
        } else {
            const newMpin = [...mpin];
            newMpin[index] = '';
            setMpin(newMpin);
        }
    };

    const handleKeyDown = (e, index) => {
        if (e.key === 'Backspace' && !mpin[index] && index > 0) inputRefs.current[index - 1].focus();
        setError('');
    };

    const handleCreateMpin = async (e) => {
        e.preventDefault();
        const enteredMpin = mpin.join('');
        const indianMobilePattern = /^[6-9]\d{9}$/;

        if (!indianMobilePattern.test(mobile)) {
            setError('Enter a valid 10-digit number');
            return;
        }

        if (!enteredMpin) {
            setError('Enter a 4 digit Mpin');
            return;
        }

        if (userMpin && (enteredMpin !== userMpin)) {
            setError('Invalid Mpin.');
            return;
        }



        try {
            setLoading(true);
            const res = await axios.post(`${API_URL}/api/auth/create-mpin`, { mobile, mpin: enteredMpin, refCode: localStorage.getItem('refferalCode') });
            let { token } = res.data;
            localStorage.setItem('authToken', token);
            localStorage.setItem('mpin', res.data.mpin);
            localStorage.setItem('mobile', res.data.mobile);
            await fetchData(res.data.mpin, res.data.mobile);
            setError('');
            setTimeout(() => {
                setLoading(false);
                navigate('/');
            }, 3000);
        } catch (error) {
            setLoading(false);
            setError(error.response?.data?.message || 'Failed to create MPIN');
        }
    };

    const handleVerifyMpin = async (e) => {
        e.preventDefault();
        const enteredMpin = mpin.join('');
        const mobile = localStorage.getItem('mobile');

        if (!enteredMpin) {
            setError('Enter a 4 digit Mpin');
            return;
        }

        if (userMpin && (enteredMpin !== userMpin)) {
            setError('Invalid Mpin.');
            return;
        }

        try {
            setLoading(true);
            const res = await axios.post(`${API_URL}/api/auth/verify-mpin`, { mpin: enteredMpin, mobile: mobile });
            if (res.status === 200) {
                localStorage.setItem('authToken', res.data.token);
                localStorage.setItem('mpin', res.data.mpin);
                localStorage.setItem('mobile', res.data.mobile);
                setIsVerified(true);
                fetchData(res.data.mpin, res.data.mobile);
                setTimeout(() => {
                    setLoading(false);
                    navigate('/');
                }, 3000);
            } else {
                setError('Invalid MPIN');
                setIsVerified(false);
            }
        } catch (error) {
            setLoading(false);
            setIsVerified(false);
            setError(error.response?.data?.message || 'Failed to verify MPIN');
        }
    };

    const fetchData = async (mpin, mobile) => {
        try {
            const res = await axios.get(`${API_URL}/api/profile/get?mpin=${mpin}&mobile=${mobile}`);

            if (res.data?.user) {
                const { aadharNumber, accountHolderName, accountNumber, bankName, fullName, ifscCode, panNumber, photo, scanner } = res.data.user;
                if (aadharNumber && accountHolderName && accountNumber && bankName && fullName && ifscCode && panNumber && photo && scanner) {
                    setIsProfileCompleted(true);
                }
            } else {
                setIsProfileCompleted(false);
            }
        } catch (error) {
            setIsProfileCompleted(false);
        }
    };

    const handleMpinInput = async (e) => {
        e.preventDefault();
        const indianMobileNumberPattern = /^[6-9]\d{9}$/;

        // Check if the entered mobile number matches the pattern
        if (!indianMobileNumberPattern.test(mobile)) {
            setError("Enter a valid mobile number.");
            return;
        }
        await fetchUser(mobile);
        setError('');
        setShowMpin(true);
    }

    const fetchUser = async (mobile) => {
        try {
            console.log(refCode);

            const res = await axios.get(`${API_URL}/api/profile/get-user-details?mobile=${mobile}`);
            console.log({ res });

            if (res.data?.user?.mPin) {
                setUserMpin(res.data?.user?.mPin);
            }
        } catch (error) {
            setError(error?.data?.message || 'Something went wrong.');
        }
    };

    if (loading) {
        <Spinner />
    }

    const checkReferalCode = async () => {
        console.log(showMpin);

        try {
            const res = await axios.get(`${API_URL}/api/auth/check-referral-code?refferalCode=${refCode}`);
            console.log(res);

            if (res.data?.user?.referalCode === refCode) {
                setRefCode(refCode);
                setOpenRefInput(false);
                localStorage.setItem('refferalCode', refCode);
            }
        } catch (error) {
            setError(error?.response?.data?.message || 'Something went wrong.');
        }
    }

    const hideRefInput = () => {
        setOpenRefInput(false);
        setError('');
    }

    return (
        <>
            <div className='mt-8 flex flex-wrap justify-center items-center'>
                <img src="/MainLogo.png" alt="" />
            </div>
            <div className='flex flex-wrap flex-col justify-center items-center login-wrapper'>
                {!isProfileCompleted ? (
                    <>
                        {
                            showMpin ? (
                                <>
                                    <h2 className='mt-10'>{userMpin ? 'Enter' : 'Create'} Your MPIN</h2>
                                    <form onSubmit={handleCreateMpin} className='flex flex-col justify-center items-center gap-1 mt-4 mb-8'>
                                        <div className='flex flex-row justify-center items-center gap-4'>
                                            {mpin.map((digit, index) => (
                                                <input key={index} type="text" value={digit} onChange={(e) => handleMpinChange(e, index)} onKeyDown={(e) => handleKeyDown(e, index)} maxLength={1} ref={(el) => (inputRefs.current[index] = el)} className='mpin-input' />
                                            ))}
                                        </div>
                                        <div className='flex flex-wrap flex-row ml-4 mt-4'>
                                            <Form.Check inline name="mpin-checkbox" type="checkbox" id="mpin-checkbox" className='mt-0' checked={isChecked} onChange={(e) => setIsChecked(e.target.checked)} />
                                            <div className='ml-2 text-red-600'>Please write down  this MPIN for login and <br />
                                                don't disclose this MPIN to anyone. <br />
                                                This is very confidential.</div>
                                            {/* {refCode && <p className='mt-4'>Referral Code: {refCode}</p>} */}
                                        </div>
                                        {error && <p className='mt-4 text-center text-red-600'>{error}</p>}
                                        <button
                                            type="submit"
                                            className='primary-button primary-btn mt-4'
                                            disabled={!isChecked || !mpin.join('')}
                                            style={{ backgroundColor: !isChecked || !mpin.join('') ? '#D9D9D9' : '#F7931A' }}
                                        >
                                            Submit
                                        </button>
                                    </form>
                                </>
                            ) : (
                                !openRefInput && (
                                    <>
                                        <h2 className='mt-10'>Enter Your Mobile Number</h2>
                                        <form onSubmit={handleMpinInput} className='flex flex-col justify-center items-center gap-1 mt-4 mb-8'>
                                            <input type="number" placeholder="Enter mobile number" value={mobile} onChange={(e) => { setMobile(e.target.value); setError('') }} className='mobile-number-input' />
                                            {error && <p className='mt-4 text-red-600'>{error}</p>}

                                            <div className='flex flex-wrap flex-row ml-4 mt-4'>
                                                <Form.Check inline name="mpin-checkbox" type="checkbox" id="mpin-checkbox" className='mt-0' checked={openRefInput} onChange={(e) => setOpenRefInput(e.target.checked)} />
                                                <div className='ml-2 text-red-600'>Do you have referral code ?</div>
                                                {/* {refCode && <p className='mt-4'>Referral Code: {refCode}</p>} */}
                                            </div>

                                            <button type="submit" className='primary-button primary-btn mt-4' disabled={!mobile}>Next</button>
                                        </form>
                                    </>
                                )
                            )
                        }
                        {openRefInput && (
                            <Popup
                                title="Referral Code"
                                show={openRefInput}
                                onHide={hideRefInput}
                                child={
                                    <div>
                                        <h2 className='mt-10'>Enter Your Referral Code</h2>
                                        <input
                                            type="text"
                                            placeholder=""
                                            value={refCode}
                                            onChange={(e) => setRefCode(e.target.value)}
                                            className='referral-code-input mobile-number-input mt-4'
                                        />
                                        {error && <p className='mt-4 text-center text-red-600'>{error}</p>}

                                        <button type="button" className='primary-button primary-btn mt-4' onClick={checkReferalCode}>Next</button>
                                    </div>
                                }
                            />
                        )}
                    </>
                ) : (
                    <>
                        <h2 className='mt-10'>Enter Your MPIN</h2>
                        <form onSubmit={handleVerifyMpin} className='flex flex-col justify-center items-center gap-1 mt-4 mb-8'>
                            <div className='flex flex-row justify-center items-center gap-4'>
                                {mpin.map((digit, index) => (
                                    <input key={index} type="text" value={digit} onChange={(e) => handleMpinChange(e, index)} onKeyDown={(e) => handleKeyDown(e, index)} maxLength={1} ref={(el) => (inputRefs.current[index] = el)} className='mpin-input' />
                                ))}
                            </div>
                            {error && <p className='mt-4 text-red-600'>{error}</p>}
                            <button type="submit" className='primary-button primary-btn mt-4'>Login</button>
                        </form>
                    </>
                )}
            </div>
        </>
    );
};

export default Login;
