import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import { PhotoIcon } from '@heroicons/react/24/solid';
import Tesseract from 'tesseract.js';
import Swal from 'sweetalert2';
import qs from 'qs';
import './Portfolio.css';
import { uploadImageToCloudinary } from '../../helper';
import Spinner from '../Loader/Spinner';

const PaymentPlan = ({ API_URL, token }) => {
    const navigate = useNavigate();
    const { asset, plan, amount } = useParams();
    const [details, setDetails] = useState(null);
    const images = ['Sachin_Scanner.jpeg', 'mcoins_qr.png'];
    const [currentImageIndex, setCurrentImageIndex] = useState(0);
    const [countdown, setCountdown] = useState(120);
    const [openSSinput, setOpenSSinput] = useState(false);
    const [uploadedScreenshot, setUploadedScreenshot] = useState(null);
    const [transactionData, setTransactionData] = useState({});
    const [planName, setPlanName] = useState('');
    const [planPrice, setPlanPrice] = useState('');
    const [isLoading, setIsLoding] = useState(false);
    const [copied, setCopied] = useState(false); // State to handle "Link Copied" message
    const screenShotRef = useRef(null);

    const upiId = '326940782881913@cnrb';

    useEffect(() => {
        if (plan === 'standard-saving-plan') {
            setPlanName('Standard Saving Plan');
            setPlanPrice('1000 - 24000');
        } else if (plan === 'super-saving-plan') {
            setPlanName('Super Saving Plan');
            setPlanPrice('25000 - 49000');
        } else if (plan === 'bumper-saving-plan') {
            setPlanName('Bumper Saving Plan');
            setPlanPrice('50,000 - 99000');
        } else if (plan === 'ultimate-saving-plan') {
            setPlanName('Ultimate Saving Plan');
            setPlanPrice('1,00,000 - *depends on you');
        }
    }, [plan]);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown((prevCountdown) => {
                if (prevCountdown === 1) {
                    setCurrentImageIndex((prevIndex) => (prevIndex + 1));
                    return 120;
                }
                return prevCountdown - 1;
            });
        }, 1000);

        return () => clearInterval(interval);
    }, [images.length]);

    const handleNav = () => {
        navigate(`/deposit/${plan}`);
    };

    const openPaymentSsInput = () => {
        setOpenSSinput(true);
    };

    const handleScreenshotUploadClick = () => {
        screenShotRef.current.click();
    };

    const preprocessImage = (imageData) => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');
        const img = new Image();
        img.src = imageData;

        return new Promise((resolve) => {
            img.onload = () => {
                canvas.width = img.width;
                canvas.height = img.height;
                // Convert to grayscale
                ctx.drawImage(img, 0, 0, img.width, img.height);
                const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);
                const data = imageData.data;

                for (let i = 0; i < data.length; i += 4) {
                    const avg = (data[i] + data[i + 1] + data[i + 2]) / 3;
                    data[i] = avg;
                    data[i + 1] = avg;
                    data[i + 2] = avg;
                }

                ctx.putImageData(imageData, 0, 0);
                resolve(canvas.toDataURL()); // Returns preprocessed image data
            };
        });
    };


    const handleScreenshotUpload = async (event) => {
        const file = event.target.files[0];
        if (file) {
            const reader = new FileReader();
            setIsLoding(true);
            reader.onload = async (e) => {
                const imageData = e.target.result;

                // Preprocess the image before passing it to Tesseract
                const preprocessedImage = await preprocessImage(imageData);

                setUploadedScreenshot(preprocessedImage);

                Tesseract.recognize(
                    preprocessedImage,
                    'eng',
                    // { logger: (m) => console.log(m) }
                ).then(({ data: { text } }) => {

                    const transactionId = text.match(/Transaction ID\s*T([0-9]+)/)?.[1] || '';
                    const transactionDate = text.match(/(\d{1,2} [A-Za-z]+ \d{4})/)?.[1] || '';  // Matches the format "15 Oct 2024"
                    const transactionTime = text.match(/(\d{1,2}:\d{2} (AM|PM))/)?.[1] || '';  // Matches "10:20 AM" or "12:30 PM"
                    const debitedAmount = text.match(/Debited from\s*\[\d\]\s*XXXXXX\d+\s*(\d+)/)?.[1] || '';

                    // Set the extracted data in state
                    setTransactionData({
                        transactionId,
                        transactionDate,
                        transactionTime,
                        purchasedAmount: debitedAmount,
                    });
                    setIsLoding(false);
                }).catch((error) => {
                    console.error('Error performing OCR:', error);
                    setIsLoding(false);

                });
            };
            reader.readAsDataURL(file);
        }
    };


    const handleSubmit = async (e) => {
        e.preventDefault();

        const token = localStorage.getItem('authToken');

        if (!token) {
            navigate('/');
            return;
        }

        // Parse the range
        const [minRange, maxRange] = planPrice.split(' - ').map(value => parseInt(value.replace(/,/g, '')));

        if (transactionData && transactionData.transactionId) {
            const purchasedAmountInt = transactionData.purchasedAmount ? parseInt(transactionData.purchasedAmount) : 0;
            if (purchasedAmountInt < minRange || purchasedAmountInt > maxRange || purchasedAmountInt % 1000 !== 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Amount',
                    text: `The amount should be between ${minRange} and ${maxRange}, and a multiple of 1000.`,
                    confirmButtonColor: '#F7931A'
                });
                return;
            }
        } else {
            if (amount < minRange || amount > maxRange || amount % 1000 !== 0) {
                Swal.fire({
                    icon: 'error',
                    title: 'Invalid Amount',
                    text: `The amount should be between ${minRange} and ${maxRange}, and a multiple of 1000.`,
                    confirmButtonColor: '#F7931A'
                });
                return;
            }
        }
        setIsLoding(true);
        // Upload transaction image to Cloudinary and get URL
        let transactionImage = null;
        if (uploadedScreenshot) {
            try {
                transactionImage = await uploadImageToCloudinary(uploadedScreenshot);
            } catch (error) {
                console.error('Error uploading scanner photo:', error);
                transactionImage = null;
            }
        }

        // Proceed with the API call if validation passes
        try {
            const data = {
                name: planName,
                range: planPrice,
                purchasedAmount: transactionData && transactionData.purchasedAmount ? transactionData.purchasedAmount : amount,
                transactionId: transactionData && transactionData.transactionId ? transactionData.transactionId : '',
                transactionDate: transactionData && transactionData.transactionDate ? transactionData.transactionDate : '',
                transactionTime: transactionData && transactionData.transactionTime ? transactionData.transactionTime : '',
                transactionImage: transactionImage,
            };

            const config = {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            };

            const res = await axios.post(`${API_URL}/api/plan/create`,
                qs.stringify(data),
                config
            );

            if (res.status === 200) {
                setIsLoding(false);
            }

            Swal.fire({
                icon: 'success',
                title: 'Plan Purchased',
                text: 'Your plan has been purchased successfully!',
                confirmButtonColor: '#F7931A'
            }).then(() => {
                let timerInterval;

                // Show loader with a countdown timer for 4 seconds
                Swal.fire({
                    title: 'Redirecting...',
                    html: 'Redirecting in <b></b> seconds.',
                    timer: 4000,
                    timerProgressBar: true,
                    showConfirmButton: false,
                    allowOutsideClick: false,
                    willOpen: () => {
                        Swal.showLoading();
                        const b = Swal.getHtmlContainer().querySelector('b');
                        timerInterval = setInterval(() => {
                            b.textContent = Math.ceil(Swal.getTimerLeft() / 1000);
                        }, 100);
                    },
                    willClose: () => {
                        clearInterval(timerInterval);
                    }
                }).then(() => {
                    if (res.status === 200) {
                        navigate(`/portfolio`);
                    } else {
                        navigate('/');
                    }
                });
            });


        } catch (err) {
            setIsLoding(false);
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Something went wrong. Please try again!',
                confirmButtonColor: '#F7931A'
            });
        }
    };

    const handleCopyCode = () => {
        navigator.clipboard.writeText(upiId).then(() => {
            setCopied(true); // Set copied state to true
            setTimeout(() => {
                setCopied(false); // Hide "Link Copied" message after 2 seconds
            }, 2000);
        }).catch(err => {
            console.error('Failed to copy text: ', err);
        });
    };

    return (
        <>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={handleNav}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span className='plan-name'>{planName} <FontAwesomeIcon icon={faAngleRight} />  make payment</span>
            </div>
            <div className='refer-container flex flex-wrap flex-col justify-center items-center gap-5 mt-6'>
                {
                    !openSSinput ? (

                        <div className='wrap'>
                            <div className='mt-4 pl-4'>
                                <span style={{ color: '#343A40' }}>UPI ID</span>
                                <InputGroup className="mb-3 referal-wrap mt-2">
                                    <Form.Control
                                        placeholder="UPI ID"
                                        aria-label="UPI ID"
                                        aria-describedby="basic-addon2"
                                        disabled
                                        value={upiId}
                                        className='mb-2'
                                    />
                                    <Button variant="outline-secondary" id="button-addon2" onClick={handleCopyCode}>
                                        Copy Code
                                    </Button>
                                </InputGroup>
                                {copied && <span style={{ color: '#F7931A' }}>Code Copied!</span>} {/* Display "Link Copied" message */}
                            </div>
                            <div className='countdown mt-4 ml-4'>
                                Complete payment in: {countdown} seconds
                            </div>
                            <div className='mt-4 pl-4'>
                                <InputGroup className="mb-3 mt-2 share" onClick={openPaymentSsInput}>
                                    <Button variant="outline-secondary" id="button-addon2">
                                        Complete Payment
                                    </Button>
                                </InputGroup>
                            </div>
                        </div>
                    ) : (
                        <div className="col-span-full">
                            <label htmlFor="scanner" className="block text-md font-medium leading-6 text-gray-900">
                                Upload Payment Screenshot
                            </label>
                            <div className="mt-2 flex items-center gap-x-3">
                                <PhotoIcon aria-hidden="true" className="h-12 w-12 text-gray-300" />
                                <button
                                    type="button"
                                    className="rounded-md px-2.5 py-1.5 text-sm font-semibol ring-inset"
                                    style={{ backgroundColor: "#F7931A", borderColor: "#F7931A", color: "#fff", border: "1px solid #F7931A" }}
                                    onClick={handleScreenshotUploadClick}
                                    disabled={isLoading}
                                >
                                    <input id="scanner" name="scanner" type="file" className="sr-only" accept="image/*" ref={screenShotRef} onChange={handleScreenshotUpload} disabled={isLoading} />
                                    Choose
                                </button>
                            </div>
                            {
                                isLoading ? (
                                    <div>
                                        <Spinner />
                                    </div>
                                ) : (
                                    <>
                                        {uploadedScreenshot ? (
                                            <>
                                                <div className="mt-4">
                                                    <img src={uploadedScreenshot} alt="Uploaded Screenshot" className="uploaded-screenshot-preview" style={{ maxWidth: '100%', height: 'auto' }} />
                                                </div>
                                                <div className='mt-4 pl-4'>
                                                    <InputGroup className="mb-3 mt-2 share" onClick={handleSubmit}>
                                                        <Button variant="outline-secondary" id="button-addon2">
                                                            Done
                                                        </Button>
                                                    </InputGroup>
                                                </div>
                                            </>
                                        ) : (
                                            <div className='mt-4' style={{ color: 'red', fontSize: '0.9rem' }}>* Upload screenshot to complete transaction</div>
                                        )}
                                    </>
                                )
                            }
                        </div>
                    )
                }
                <div className='text-center mt-4' style={{ color: '#999999', fontSize: '0.8rem' }}>
                    * Block/Time will be calculated after the transaction is generated and broadcasted
                </div>
            </div>
        </>
    );
};

export default PaymentPlan;
