import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign, faAngleLeft } from '@fortawesome/free-solid-svg-icons';
import './index.css';
import Spinner from '../Loader/Spinner';

const UserPlans = ({ API_URL, token }) => {
    const [loading, setLoading] = useState(false);
    const [plans, setPlans] = useState([]);
    const { id } = useParams(); // Access the 'id' parameter from the route

    const navigate = useNavigate();

    const handleNav = () => {
        if (id) {
            navigate('/profile');
        } else {
            navigate('/portfolio');
        }
    };

    // Helper function to format date and time
    const formatDateTime = (dateString) => {
        const options = {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
        };
        return new Intl.DateTimeFormat('en-GB', options).format(new Date(dateString));
    };

    // Helper function to calculate daily income based on the plan name
    const calculateDailyIncome = (plan, amount) => {
        let dailyRate = 0;

        switch (plan) {
            case 'Standard Saving Plan':
                dailyRate = 0.03;  // 3%
                break;
            case 'Super Saving Plan':
                dailyRate = 0.04;  // 4%
                break;
            case 'Bumper Saving Plan':
                dailyRate = 0.04;  // 4%
                break;
            case 'Ultimate Saving Plan':
                dailyRate = 0.05;  // 5%
                break;
            default:
                dailyRate = 0;  // Default case
        }

        return amount * dailyRate;
    };

    useEffect(() => {
        setLoading(true);
        const fetchData = async () => {
            try {
                const config = {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        'Content-Type': 'multipart/form-data',
                    },
                };

                const response = await axios.get(`${API_URL}/api/plan/user-plans`, config);
                if (response.status === 200) {
                    setPlans(response.data.plans);
                    setLoading(false);
                }
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, [API_URL, token]);

    const showAllPlans = () => {
        navigate('/top-portfolio');
    };
    console.log(plans);

    return (
        <div className='portfolio-wrap'>
            <div className='mt-6 flex flex-wrap flex-row items-center justify-start gap-4 bread-nav' onClick={handleNav}>
                <FontAwesomeIcon icon={faAngleLeft} />
                <span className='plan-name'>Your plan</span>
            </div>

            {
                loading ? (
                    <div>
                        <Spinner />
                    </div>
                ) : (
                    <>
                        {
                            plans.length > 0 ? (
                                plans.map((plan, id) => (
                                    <div className='plan-wrap mb-4' key={id}>
                                        <div className='plan-bg'>
                                            <div className='flex flex-wrap flex-col items-center justify-center mb-4'>
                                                <span className='text-center'>Plan - {plan?.name}</span>
                                                <span>Guaranteed Return</span>
                                            </div>
                                            <hr />
                                            <div className='flex flex-wrap flex-col mt-4'>
                                                <div>
                                                    <span>Plan ID : </span><span>{plan?._id}</span>
                                                </div>
                                                <div>
                                                    <span>Investment : </span><span>{plan?.purchasedAmount} rs</span>
                                                </div>
                                                <div>
                                                    <span>Daily Income : </span>
                                                    <span>{plan?.status !== 'pending' ? calculateDailyIncome(plan?.name, plan?.purchasedAmount) : '0'} rs</span>
                                                </div>
                                                <div>
                                                    <span>Plan Start Date and Time : </span>
                                                    <span>{formatDateTime(plan?.createdAt)}</span>
                                                </div>
                                                <div>
                                                    <span>Status : </span><span>{plan?.status}</span>
                                                    <span>
                                                        <img color='#fff' className='inline ml-2' src={`/${plan?.status}.png`} />
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            ) : (
                                <>
                                    <div style={{ color: '#26273A', fontSize: '1.2rem', textAlign: 'center' }} className='mt-28'>
                                        No results found.
                                    </div>
                                    <div style={{ color: '#5DC486', fontSize: '1.2rem', textAlign: 'center', textDecoration: 'underline' }} className='mt-10' onClick={showAllPlans}>View Suggested plans</div>
                                </>
                            )
                        }
                    </>
                )
            }
        </div>
    );
};

export default UserPlans;
